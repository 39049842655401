<template>
  <footer class="footer">
    <div class="textFooter container">
      <div class="contacts">
        <h4>¿Hablamos? —</h4>
        <div class="contact">
          <a
            href="https://www.instagram.com/patriborrallo/"
            target="_blank"
            rel="noopener"
            >Instagram</a
          >
          <a
            href="https://www.linkedin.com/in/patriciaborrallo/"
            target="_blank"
            rel="noopener"
            >Linkedin</a
          >
          <a
            href="https://twitter.com/PatriBorralloP"
            target="_blank"
            rel="noopener"
            >Twitter</a
          >
          <a
            href="https://www.behance.net/patriborrallo"
            target="_blank"
            rel="noopener"
            >Behance</a
          >
        </div>

        <p>
          No dudes en contactarme a través de cualquiera de esos canales o
          simplemente puede enviarme un correo electrónico diciendo

          <strong class="hello"
            >"Hola"👋 → <a class="contact" href="mailto:hola@patriborrallo.com">
              hola@patriborrallo.com</a
            ></strong
          >
        </p>

        <p>38°58′26″N – 5°48′1″O</p>
      </div>
      <div class="copyright">
        Diseñada en Badajoz con ❤ by
        <a class="contact" href="https://patriborrallo.com">PatriBorrallo</a>
        ©2021
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.footer {
  width: 100%;
  background: #f0f7fa;
  padding: 4rem 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.textFooter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.contacts {
  max-width: 31rem;
  display: flex;
  flex-direction: column;
  h4 {
    margin: 0 0 1.5rem 0;
    font-weight: 600;
  }

  .contact {
    display: flex;
    flex-flow: row wrap;
  }
  a {
    font-weight: 600;
  }
  a:not(:last-child) {
    margin: 0 1rem 0 0;
  }
  p {
    margin: 0 0 1.5rem 0;
  }

  .hello {
    display: flex;
    align-items: baseline;
    a {
      padding: 1rem;
    }
  }
}

.copyright {
  margin: 3rem 0 0 0;
  font-size: 0.8rem;
  a {
    font-size: 0.8rem;
    font-weight: 600;
  }
}
</style>
