<template>
  <main class="container" >
    <ul id="portfolio" class="projects portfolio">
      <li v-for="item in object" :key="item" class="project " data-aos="fade-up"
     data-aos-duration="1000">
        
        <div class="info" >
          <div class="name">
            <h2>{{ item.name }}</h2>
            <div class="category">
              {{ item.category }}
            </div>
          </div>
          <p>
            {{ item.description }}
          </p>
          <div class="button">
            <router-link :to="{ path: item.link }" class="link"
              >{{ item.msgButton }}<span class="material-icons iconGo"> east </span>
            </router-link>
          </div>
        </div>

        <router-link class="imagen" :to="{ path: item.link }">
          <img :src="item.img" :alt="item.name"
        /></router-link>

      </li>
    </ul>
  </main>
</template>

<script>
export default {
  name: "Work",
  props: {
    msg: String,
  },
  data() {
    return {
      object: [
        {
          name: "Vera",
          category: "Research, UX/UI Design",
          description:
            "El proyecto Vera consiste en una aplicación de alimentación saludable para ayudar a los usuarios a que sean más conscientes de lo que consumen y así puedan mejorar su alimentación y su salud.",
          id: 1,
          link: "/Vera",
          img: "img/vera.png",
          msgButton: "Ver más",
        },
        {
          name: "Mibus",
          category: "Research, UX/UI Design",
          description:
            "El proyecto Mibus consiste en una aplicación para la gestión del transporte público de la ciudad que sirve para ayudar a los usuarios a visualizar los trayectos, sus paradas y gestiona su tarjeta transporte. ",
          id: 3,
          link: "/Mibus",
          img: "img/mibus.png",
          msgButton: "Ver más",
        },
        {
          name: "Mimo",
          category: "Research, UX/UI Design",
          description:
            "El proyecto Mimo consiste en una web para informar y concienciar del uso de la protección contra la radiación solar.",
          id: 2,
          link: "/Mimo",
          img: "img/mimo.png",
          msgButton: "Ver más",
        },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss"  scoped >
.container{
  margin-bottom: 6rem;
}
.projects {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.project {
  width: 100%;
  height: auto;
  display: flex;
  margin: 8rem 0;
  justify-content: space-between;
  align-items: center;

  .imagen {
    max-width: 44rem;
    width: 100%;
    height: auto;
    color: pink;
    overflow: hidden;
    transition: all 0.8s ease-in-out;
    -webkit-transition: all 0.8s ease-in-out;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: grayscale(10%);
      transition: all 0.8s ease-in-out;
      -webkit-transition: all 0.8s ease-in-out;
    }
    img:hover {
      filter: none;
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
  }

  .imagen:hover {
    filter: drop-shadow(5px 20px 30px rgba(34, 42, 65, 0.2));
  }
}

.info {
  max-width: 24.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
   padding: 0 1.5rem 0 0;
  .name {
    margin: 0 0 1.5rem 0;
    .category {
      font-weight: 400;
      font-size: 0.9rem;
      letter-spacing: 0.15em;
      text-transform: uppercase;
      color: #a0aac5;
    }
  }
}

ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
}
</style>
