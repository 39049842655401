<template>
  <div class="home">
    <header class="top container">
      <div class="profile">
        <div class="textTop" data-aos="fade-up" data-aos-duration="1000">
          <h1>
            Hola 👋, Soy <b> Patricia Borrallo</b> — Diseñadora de
            <b>producto digital</b>, apasionada en resolver problemas ✨
          </h1>

          <div class="button more">
            <router-link to="/about"
              >Más sobre mí<span class="material-icons iconGo">
                east
              </span></router-link
            >
          </div>
        </div>

        <div class="imgDesign">
          <a
            href="mailto:hola@patriborrallo.com"
            class="contactar"
            data-aos="fade-up"
            data-aos-duration="2000"
            >🌻<img src="img/Design.svg" alt="Imagen con el texto Diseño de producto"
          /></a>
        </div>
      </div>
      <div class="arrow bounce">
        <a href="#portfolio" class="material-icons"> south </a>
      </div>
    </header>

    <Work />
  </div>
</template>

<script>
import Work from "@/components/Work.vue";

export default {
  name: "Home",
  components: {
    Work,
  },
};
</script>

<style lang="scss"  >
.home {
  header {
    height: 95vh;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .profile {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      height: 100%;
      padding: 6rem 0 0 0;
    }
  }
}
.textTop {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 4rem 0 0 0;
}

.arrow {
  display: flex;
  justify-content: center;
  margin: 2rem 0;
  .material-icons {
    animation: bounce-in 0.5s;
    text-decoration: none;
    padding: 1rem;
  }
}

.imgDesign {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 25%;

  .contactar {
    position: relative;
    animation: rotating-function 15s linear infinite;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    padding: 0;
    top: 20%;
    right: 30%;

    img {
      position: absolute;
      width: 12rem;
      height: 12rem;
    }
  }
}
.contactar:hover {
  cursor: url("/img/Cursor.svg"), auto;
}

@keyframes rotating-function {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>