<template>
  <div id="app">
    <link
      href="https://fonts.googleapis.com/icon?family=Material+Icons"
      rel="stylesheet"
    />
    <nav id="nav">
      <div class="nav container">
        <router-link to="/" class="name logo">Patri Borrallo.</router-link>
        <router-link to="/about" class="button">about</router-link>
      </div>
    </nav>
    <router-view />
    <Footer />
  </div>
</template>

<script >
import Footer from "@/components/Footer.vue";

export default {
  name: "Vera",
  components: {
    Footer,
  },
};
</script>

<style lang="scss" >



$color-dark: #404b69;
$color-blue: #dbedf3;
$color-red: #f73859;
$color-n: #a0aac5;

* {
//border: solid 1px pink;
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  color: $color-dark;
  text-align: left;
  margin: 0;
}

#app {
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;

}

::selection {
  background: $color-dark;
  color: $color-blue;
}

/* Firefox */
::-moz-selection {
  background: $color-dark;
  color: $color-blue;
}

#nav {
  top: 1rem;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 5;

  .nav {
    display: flex;
    justify-content: space-between;
    font-weight: 600;     
    text-decoration: none;
  }
}
a {
  text-decoration: none;
  padding: 1rem 0;
 
}

.container {
  padding: 0 1.5rem;
  max-width: 1224px;
  margin: 0 auto;
}

#nav a.router-link-exact-active {
  color: $color-red;
}

h1 {
  font-size: 2.5rem;
  font-weight: 300;
  font-style: normal;
  margin: 0 0 2rem 0;
  line-height: 3.5rem;

  b {
    font-size: 2.5rem;
   font-family: 'DM Serif Display', serif;
     font-weight: 400;
     font-style: italic;
  }
}

h2 {
  font-size: 2rem;
  margin: 0 0 1rem 0;

  b {
    font-size: 2rem;
   font-family: 'DM Serif Display', serif;
   font-weight: 400;
     font-style: italic;
  }
}
h3 {
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  font-weight: 400;

  margin: 0 0 1rem 0;
}
h4 {
  font-size: 1.5rem;
  margin: 0 0 1rem 0;
  line-height: 2.5rem;
  font-weight: 500;
}

h5 {
  font-size: 1.5rem;
  margin: 0 0 1rem 0;
  font-weight: 400;
  line-height: 2.5rem;
}

.icon {
  width: 2rem;
  height: auto;
}
p {
  font-size: 1rem;
  line-height: 2rem;
  font-weight: 400;
  strong {
    font-size: 1rem;
  }
}
p:not(:last-child) {
  margin: 0 0 3rem 0;
}

.button {
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    font-weight: 600;
    color: $color-dark;
    font-size: 1rem;
    padding: 1rem 1rem 1rem 0;
    span {
      margin: 0 0 0 1rem;
    }
  }
}
a:hover {
  color: $color-red;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sectionText {
  display: flex;
  justify-content: space-between;
  margin: 6rem auto;

  .text {
    max-width: 44rem;
  }
  .titlesection {
    width: 24.5rem;
    margin: 0 1.5rem 1.5rem 0;
    min-width: 18rem;
    h2 {
      max-width: 24.5rem;
      min-width: 18rem;
      width: 100%;
      margin: 0 1.5rem 1.5rem 0;
    }
  }
}

//-------------------------

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}
@mixin animation($animation) {
  -webkit-animation: #{$animation};
  -moz-animation: #{$animation};
  -ms-animation: #{$animation};
  animation: #{$animation};
}
@mixin transform($transform) {
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -ms-transform: $transform;
  transform: $transform;
}

@include keyframes(bounce) {
  0%,
  20%,
  50%,
  80%,
  100% {
    @include transform(translateY(0));
  }
  40% {
    @include transform(translateY(-20px));
  }
  60% {
    @include transform(translateY(-10px));
  }
}

.bounce {
  @include animation(bounce 2s infinite);
}

@include keyframes(iconGo) {
  100% {
    @include transform(translateX(0));
  }
  0% {
    @include transform(translateX(-10px));
  }
}

.button:hover {
  .iconGo {
    @include animation(iconGo 1s 1 alternate);
    color: #f73859;
  }
}

//-------------------------

@media only screen and (min-width: 1224px) {
  .process {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (max-width: 1224px) {
  #app {
    .projects {
      .project {
        margin: 6rem 0;
      }
      .imagen {
        width: 100%;
        height: auto;
      }
    }

    .process {
      .list {
        overflow-x: scroll;
      }
    }

    .list::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    .list::-webkit-scrollbar:vertical {
      width: 10px;
    }

    .list::-webkit-scrollbar-button:increment,
    .list::-webkit-scrollbar-button {
      display: none;
    }

    .list::-webkit-scrollbar:horizontal {
      height: 10px;
    }

    .list::-webkit-scrollbar-thumb {
      background-color: #a0aac5;
      border-radius: 20px;
      border: 2px solid #f0f7fa;
    }

    .list::-webkit-scrollbar-track {
      border-radius: 10px;
    }
  }
}

@media only screen and (max-width: 768px) {
  #app {
    .portfolio {
      height: 100%;
      .project {
        flex-direction: column;
        height: auto;
        margin: 0 0 3rem 0;
        align-items: start;
        padding: 2rem 0;

        .info {
          max-width: 30rem;
          order: 2;
          margin: 2rem 0 0 0;
          display: flex;
          flex-direction: column;
          padding: 0;
        }
        .imagen {
          width: 100%;
          height: auto;
        }
      }
    }

    .about {
      header {
        display: flex;
        flex-direction: column;
        height: auto;
        h1 {
          width: 100%;
        }
        .imgMain {
          position: relative;
          top: 0;
          width: 100%;
        }
      }

      .img_bg {
        display: none;
      }

      .bio {
        flex-direction: column;
      }
    }

    .project {
      .information {
        flex-direction: column;
      }
      .data {
        max-width: none;
        width: 100%;
        margin: 0 0 1.5rem 0;
      }
      .itemdata {
        margin: 0 0 2rem 0;
      }
 
    }

    .sectionText {
      flex-direction: column;
      .titlesection {
        width: 100%;
      }
      iframe {
        height: 200px;
      }
    }
  }
}

@media only all and (max-width: 568px) {
  #app {
    .home {
      header {
        height: 90vh;
        .profile {
          .more {
            display: none;
          }
          .textTop {
            width: 100%;
          }
          .imgDesign {
            display: none;
          }
        }
      }
    }

    .projects {
      height: 100%;

      .project {
        .item {
          width: 100%;
        }
      }
    }

    iframe {
      height: 30rem;
    }

    .textContext,
    section {
      margin: 3rem auto;
    }

    .about {
      header {
        padding: 0 0 0 0;
        .imgMain {
          position: relative;
        }
      }

      .img_bg {
        display: none;
      }
      
    }
  }
}

@media only all and (max-width: 320px) {
  #app {
    .home {
      header {
        height: 90vh;
        .textTop {
          padding: 6rem 0 0 0;
          width: 100%;
        }
      }
    }
    h1 {
      font-size: 2rem;
      line-height: 3rem;
      b {
        font-size: 2rem;
      }
    }
    .pagination {
      .link {
        font-size: 2rem;
      }
    }
  }
}
</style>
